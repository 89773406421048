import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Import Helmet
import ScratchCard from './ScratchCard'; // Assurez-vous d'importer votre composant ScratchCard

const ScratchCardOnlyPage = () => {
  const { id } = useParams();
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');
  const [textSize, setTextSize] = useState(24);
  const [textColor, setTextColor] = useState('#000000');
  const [brushSize, setBrushSize] = useState(30);
  const [marginTop, setMarginTop] = useState(0);
  const [lineHeight, setLineHeight] = useState(1.5);
  const [textHeight, setTextHeight] = useState(0);

  // Charger dynamiquement le CSS uniquement pour cette page
  useEffect(() => {
    import('./ScratchCardOnlyPage.css'); // Import dynamique du CSS
  }, []);

  // Désactiver le scroll sur le body quand cette page est affichée
  useEffect(() => {
    document.body.style.overflow = 'hidden'; // Désactive le scroll

    // Nettoyage : Réactiver le scroll lorsque le composant est démonté
    return () => {
      document.body.style.overflow = 'auto'; // Réactive le scroll
    };
  }, []);

  // Fetch the card data based on the ID
  useEffect(() => {
    const API_URL = `https://joli-mot.fr/apiEtsy.php?cleApi=34KVHFQSP226YTk4n256QP6rGbqhA5w2ymY7W3pLwdbuc3i75&service=getCarte&id=${id}`;
    
    fetch(API_URL)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setConfig(data);
        setMessage(decodeHtml(data.message || ''));
        setTextSize(data.taille_text || 24);
        setTextColor(data.couleur_text || '#000000');
        setBrushSize(30);
        setMarginTop(data.margin_top_gratte || 0);
        setLineHeight(data['line-height'] || 1.5);
        setTextHeight(0);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [id]);

  const decodeHtml = (html) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="scratch-card-only-page">
      {/* SEO Helmet */}
      <Helmet>
        <title>Les cartes à Gratter de Joli-mot.fr - Quelqu'un a un message pour toi !</title>
        <meta
          name="description"
          content={`Découvrez le message de votre carte à gratter proposées par Joli-mot.fr, parfaite pour vos événements.`}
        />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <ScratchCard
        width={400}
        height={400}
        gratteSrc={`/assets_react/grattes/${config.gratte}.png`}
        brushSize={brushSize}
        text={message}
        imageSrc={`/assets_react/visuels/${config.visuel}`}
        textSize={textSize}
        textColor={textColor}
        marginTop={marginTop}
        lineHeight={lineHeight}
        textHeight={textHeight} // Utilisation de textHeight
      />
      <div className="sign">
        <span>Carte à gratter</span>
        <br />
        by <a href="https://joli-mot.fr/">joliMot.fr</a>
      </div>
    </div>
  );
};

export default ScratchCardOnlyPage;
